<template>
  <div v-if="division" class="ml-0">
    <v-row class="mb-6">
      <h1 style="margin-left:12px">{{ division.name }}</h1>
      <v-spacer></v-spacer>
      <v-btn
        color="primary"
        class="mr-2"
        icon
       
        v-if="current.permissions.DIVISIONS_WRITE"
        @click="editDivision()"
        ><v-icon>mdi-square-edit-outline</v-icon></v-btn
      >

 <v-btn
        color="primary"
        outlined
        :to="{ name: 'BudgetDivision', params: { id: division.id } }"
      >
        <span>zur Budgetübersicht</span>
        <v-icon class="ml-2">mdi-arrow-right-bold-circle-outline</v-icon>
      </v-btn>

    </v-row>
   
    <h3>Kunden in dieser Unit ({{ forecasts.find(f => f.id === $store.state.activeForecastId).title }})</h3>

    <v-card flat class="mt-4 pa-2" v-if="clientNames.length > 0">
      <v-chip
        label
        small
        class="ma-1"
        v-for="(client, index) in clientNames"
        :key="index"
        >{{ client }}</v-chip
      >
    </v-card>
    <v-card flat v-else class="mt-4 pa-4">
      Keine Kunden in dieser Unit.
    </v-card>

    <h3 class="mt-6">Benutzer mit Zugriff auf diese Unit</h3>

 <p>
      Zugriffsrechte werden über die
      <router-link :to="{ name: 'Users', params: { tenant: current.path } }"
        >Benutzerverwaltung</router-link
      >
      gesetzt.
    </p>

    <DivisionUsers :division="division"></DivisionUsers>
   
   

    <v-row class="mt-6 mb-12 pr-3">
      <v-spacer></v-spacer>
     
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import DivisionUsers from "@/components/DivisionUsers";

export default {
  data() {
    return {
      division: null,
    };
  },
  computed: {
    ...mapGetters(["current", "divisions", "clients", "clientsWithProjects","forecasts"]),
    clientNames() {
      return this.clientsWithProjects
                  .filter(c => c.divisions.includes(this.division.id))
                  .map(c => c.name)
                  .sort();


     
    },
  },
  created() {
    this.setDivision(Number(this.$route.params.id));
  },
  components: {
    DivisionUsers,
  },
  methods: {
    setDivision(id) {
      this.division = this.$store.getters.divisions.find((d) => d.id === id);

      

    },
    editDivision() {
      this.$router.push({
        name: "DivisionEdit",
        params: { id: this.division.id },
      });
    },
  },
  beforeRouteUpdate(to, from, next) {
    this.setDivision(to.params.id);
    next();
  },
};
</script>

<style scoped>
.grid {
  display: grid;
  place-content: center;
  height: 100%;
}
</style>