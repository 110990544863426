<template>
<div>
    <v-simple-table class="my-6">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">
              <v-icon class="ml-6 mr-5">mdi-account-group</v-icon
              ><span>Zugriff haben</span>
            </th>
            <th class="text-center">{{ trl('FC_DIVISION_READ__short') }}</th>
            <th class="text-center">{{ trl('FC_DIVISION_WRITE__short') }}</th>
            <th class="text-center">{{ trl('FC_DIVISION_EXPORT__short') }}</th>
            <th class="text-center">{{ trl('FC_DIVISION_COMMIT__short') }}</th>
            <th class="text-left" style="white-space: nowrap">
              nur ausgewählte Kunden
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in accessData" :key="item.user.id" :class="{hidden: item.hidden && hideGlobalUsers, transparent: item.hidden }">
            <td class="text-left" style="white-space: nowrap">
              <v-list-item>
                <v-list-item-avatar color="white" >
                  <v-img contain :src="item.user.avatar"></v-img>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title
                    >{{ item.user.firstname }}
                    {{ item.user.lastname }}</v-list-item-title
                  >
                  <v-list-item-subtitle>{{
                    item.user.role
                  }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </td>
            <td class="text-center">
              <v-icon
                >mdi-{{
                  item.FC_DIVISION_READ ? "check-circle" : "close-circle"
                }}</v-icon
              >
            </td>
            <td class="text-center">
              <v-icon
                >mdi-{{
                  item.FC_DIVISION_WRITE ? "check-circle" : "close-circle"
                }}</v-icon
              >
            </td>
            <td class="text-center">
              <v-icon
                >mdi-{{
                  item.FC_DIVISION_EXPORT ? "check-circle" : "close-circle"
                }}</v-icon
              >
            </td>
            <td class="text-center">
              <v-icon
                >mdi-{{
                  item.FC_DIVISION_COMMIT ? "check-circle" : "close-circle"
                }}</v-icon
              >
            </td>
            <td v-if="item.clients.length === 0">---</td>
            <td v-else>{{ item.clients.sort().join(", ") }}</td>
          </tr>
          <tr v-if="numAdditionalUsers > 0">
            <td colspan="6" class="pl-10" @click="toggleGlobalUsers" style="cursor:pointer">und {{ `${numAdditionalUsers} ${numerus('weiterer||weitere', numAdditionalUsers)} Benutzer aufgrund ${numerus('dessen||ihres', numAdditionalUsers)} globalen Zugriffs auf die Forecast-Zahlen.`}}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    
</div>
</template>

<script>
import { mapGetters } from "vuex";
import { numerus, trl, getClientName } from "@/utils/strings";

export default {
  name: "DivisionUsers",
  props:["division"],
  data(){
    return {
      hideGlobalUsers: true,
    }
  },
  computed: {
    ...mapGetters(["current","users", "divisions", "clients"]),
    accessData() {
      let result = [];
      
      this.users.forEach((u) => {
        let so = this.sortorder(u);

        if (!so) return;

        const tenant = u.tenants.find((t) => t.id === this.current.id);
        const permissions = Object.keys(tenant.permissions);
        let dp = {}; // [d]ivision [p]ermissions
        let clientslist = [];

        for (let p of permissions) {
          if (typeof tenant.permissions[p] === "boolean") {
            dp[p] = tenant.permissions[p];
          } else if (typeof tenant.permissions[p] === "object") {
              let divisionPerms = tenant.permissions[p].find(tp => tp.division_id === this.division.id);
              if (divisionPerms){
                  dp[p] = true;
                  clientslist = clientslist.concat(divisionPerms.clients ||[]);
              }
          }
        }

        result.push({
            so: so,
            hidden: so.toString().substr(0, 4) === '0000',
            user: {
              firstname: u.firstname,
              lastname: u.lastname,
              avatar: u.avatar,
              role: tenant.role,
              permissions: so
            },
            FC_DIVISION_READ: dp.FC_DIVISION_READ,
            FC_DIVISION_WRITE: dp.FC_DIVISION_WRITE,
            FC_DIVISION_COMMIT: dp.FC_DIVISION_COMMIT,
            FC_DIVISION_EXPORT: dp.FC_DIVISION_EXPORT,
            clients: [...new Set(clientslist)].map(id => getClientName(id)).sort((a,b) => a.localeCompare(b)),
          });

      })

      result.sort((a,b) => b.so - a.so);



      if (result.filter(x => !x.hidden).length === 0) {
        result.forEach(item => item.hidden = false);
      }

      return result;

     
    },
    numAdditionalUsers(){
      return this.accessData.filter(x => x.hidden).length;
    }
    
  },
  methods: { numerus, trl, getClientName,
  sortorder(u){

      const tenant = u.tenants.find((t) => t.id === this.current.id);
      const permissions = Object.keys(tenant.permissions)  
      
      if (!tenant.permissions.FC_DIVISION_READ || typeof tenant.permissions.FC_DIVISION_READ === "object" && !tenant.permissions.FC_DIVISION_READ.map(x => x.division_id).includes(this.division.id)) {
        return false;
      }
      let order = [];

      
      if (!tenant.permissions.FC_DIVISION_COMMIT) order.push(0)
        else if (typeof tenant.permissions.FC_DIVISION_COMMIT === "object" && tenant.permissions.FC_DIVISION_COMMIT.map(x => x.division_id).includes(this.division.id))
          order.push(1);
        else
          order.push(0);
        
      if (!tenant.permissions.FC_DIVISION_WRITE) order.push(0)
        else if (typeof tenant.permissions.FC_DIVISION_WRITE === "object" && tenant.permissions.FC_DIVISION_WRITE.map(x => x.division_id).includes(this.division.id))
          order.push(1);
        else
          order.push(0);
      
       if (!tenant.permissions.FC_DIVISION_EXPORT) order.push(0)
        else if (typeof tenant.permissions.FC_DIVISION_EXPORT === "object" && tenant.permissions.FC_DIVISION_EXPORT.map(x => x.division_id).includes(this.division.id))
          order.push(1);
        else
          order.push(0);

      if (typeof tenant.permissions.FC_DIVISION_READ === "object" && tenant.permissions.FC_DIVISION_READ.map(x => x.division_id).includes(this.division.id)){
            let clients = tenant.permissions.FC_DIVISION_READ.clients || [];

            if (clients.length === 0) {
              order.push(2);
            } else {
              order.push (1);
            }
      } else {
        order.push(0)
      }

      if (tenant.permissions.TENANTS_MANAGE) {
        order.push(0)
      } else {
        order.push(1)
      }




      if (tenant.permissions.FC_DIVISION_WRITE) {
        order.push(1)
      } else {
        order.push(0)
      }





      let x = 0;
      if (typeof tenant.permissions.FC_DIVISION_COMMIT === "boolean") {
          x+= tenant.permissions.FC_DIVISION_COMMIT ? 2 : 1
      }

      if (typeof tenant.permissions.FC_DIVISION_WRITE === "boolean") {
          x+= tenant.permissions.FC_DIVISION_WRITE ? 2 : 1
      }

      if (typeof tenant.permissions.FC_DIVISION_EXPORT === "boolean") {
          x+= tenant.permissions.FC_DIVISION_EXPORT ? 2 : 1
      }

      if (typeof tenant.permissions.FC_DIVISION_READ === "boolean") {
          x+= tenant.permissions.FC_DIVISION_READ ? 2 : 1
      }

      order.push(x);
      order.push(tenant.role_id);
      return order.join("");
  },
  toggleGlobalUsers(){
    this.hideGlobalUsers = !this.hideGlobalUsers;
  }
  }
};
</script>

<style scoped>
.mdi-check-circle{
    color: green;
}

.mdi-close-circle{
    color: red;
   
}

.transparent{
  opacity:.75;
}

.hidden{
  display: none;
}

</style>